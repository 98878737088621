<template>
    <a class="navbar-brand mx-auto text-lg-center" href="#">
        <a href="https://www.linkedin.com/in/steven-thompson-10a5071b" target="_blank">
            <img class="mb-3 rounded-circle d-none d-lg-inline-block" src="../../assets/images/Avatar.svg" alt="Steve Thompson">
        </a>
        <h1 class="fw-light">Steve Thompson</h1>
    </a>
</template>

<script>
export default {
    name: 'navbrand'
}
</script>